<template>
  <section
    class="app-container checkKenifePage app-flex-col-container"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <div class="flex-col-content row">
      <div class="l-col">
        <KenifeFiles
          ref="kenifeFiles"
          :thumbnails="thumbnails"
          :data="psdListData"
          @loadend="loadend"
          @toggleCurPsd="toggleCurPsd"
        />
      </div>
      <div class="m-col">
        <GroupLayer
          v-loading="curPsd.loading"
          element-loading-text="拼命处理中"
          element-loading-spinner="el-icon-loading"
          ref="groupLayer"
          :sup_this="this"
          :info="curGroupLayer"
          :disabled="doneSuccess"
        />
      </div>
      <div class="r-col">
        <kenifeLayer
          v-if="curPsd.nodes"
          ref="kenifeLayer"
          :sup_this="this"
          :info="curPsd"
          @toggleCurGroupLayer="toggleCurGroupLayer"
        />
      </div>
    </div>
    <div class="bottom">
      <template v-for="(item, index) in psdListData">
        <BottomBtn
          :loading="loading"
          :key="index"
          :data="item"
          :index="index"
          :psdListData="psdListData"
          :back="true"
          v-if="curPsd == item"
          @doSubmit="doSubmit"
        />
      </template>
    </div>
  </section>
</template>

<script>
import KenifeFiles from './module/kenifeFiles'
import kenifeLayer from './module/kenifeLayer'
import GroupLayer from './module/groupLayer'
import BottomBtn from './module/bottomBtn'

import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
import { add, createStardandSize, stardandAdd } from '@/api/product/productApi'
import { createRandomNum, base64ToFile } from '@/utils'
import { MAKER, SECURITY, BOUNDARY, FONT_LAYER, OTHER_LAYER, KL_IMAGE_TYPES } from '@/utils/constant'

const MAX_NUM = 4
export default {
  components: {
    KenifeFiles,
    kenifeLayer,
    GroupLayer,
    BottomBtn
  },
  data() {
    return {
      sup_this: this,
      psdListData: [],
      curPsd: {},
      curGroupLayer: {},
      thumbnails: [],
      loading: false,
      cacheUrlData: []
    }
  },
  computed: {
    ...mapGetters(['knifePsds', 'knifeStandardStatus']),
    doneSuccess() {
      return this.curPsd.success
    }
  },
  created() {
    this.createPsdListData()
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false)
    }
  },
  // 2：需要在退出页面的时候销毁这个监听事件
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false)
    //銷毀实例时候 清空
    this.clearUrlCache()
  },
  methods: {
    clearUrlCache() {
      this.cacheUrlData.map((url) => {
        URL.revokeObjectURL(url)
      })
    },

    goBack() {
      const existUndoTask = this.existUndoTask()
      // console.log('existUndoTask', existUndoTask)
      if (!existUndoTask) return
      this.$confirm(
        `检测到尺码文件
        <span style="color: #ff4949;">${existUndoTask.join('、')}</span> 未上传，是否仍然要离开当前页面？`,
        '确认信息',
        {
          distinguishCancelAndClose: true,
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      )
        .then(() => {
          this.$router.back()
        })
        .catch((action) => {
          history.pushState(null, null, document.URL)
          if (action === 'cancel') {
            this.$message({
              type: 'info',
              message: '停留在当前页面，继续上传操作'
            })
          }
        })
    },
    back() {
      this.$router.replace('/knife')
    },

    toggleCurGroupLayer(groupLayer) {
      this.curGroupLayer = groupLayer
    },

    getImageType(layerName) {
      try {
        layerName = layerName.trim() || ''

        for (let key in KL_IMAGE_TYPES) {
          if (layerName.charAt() == '分') {
            return BOUNDARY
          }
          if (layerName.charAt() === KL_IMAGE_TYPES[key].charAt()) {
            return key
          }
        }
      } catch (err) {
        console.log(err)
      }
      return OTHER_LAYER
    },
    createPsdListData() {
      if (!this.knifePsds.length) {
        return this.$router.replace('/product/maintain/knifeMange')
      }
      const psdListData = cloneDeep(this.knifePsds)
      try {
        this.loading = true
        const p = psdListData.map(async (knifeItem) => {
          const {
            files: [file]
          } = knifeItem
          //将内存的url缓存起来，等待实例销毁的时候，清空缓存
          const url = URL.createObjectURL(file)
          this.cacheUrlData.push(url)

          const psd = await this.PSD.fromURL(url)
          // this.PSD.fromDroppedFile(file)
          //获取全局缩略图信息
          const thumbnailKey = psd.resources.typeIndex.thumbnail
          const thumbnail = psd.resources.resources[thumbnailKey]

          const png = {
            src: thumbnail.url
          }
          console.log('png', png)
          // const psdInfo = psd.tree().export()
          const psdInfo = {}
          console.log(1)
          psdInfo.thumbnail = png.src
          psdInfo.thumbnalWidth = thumbnail.width
          psdInfo.thumbnalHeight = thumbnail.height
          console.log(1)
          this.thumbnails.push(png.src)
          psdInfo.file = file

          psdInfo.nodes = psd.tree().descendants()
          console.log('nodes', nodes)
          const nodes = psdInfo.nodes
          nodes.map((node, fIndex) => {
            if (node.type !== 'group' || node.depth() !== 1) return
            const children = node._children || []
            const forms = []
            console.log('fIndexfIndex', fIndex)
            children.map((item, index) => {
              const { name, top, left, width, height, text } = item.export() || {}
              const type = this.getImageType(name)
              const obj = {
                name,
                top,
                left,
                width,
                height,
                type: type + ''
              }
              if (text) {
                const { value, transform, font } = text
                let size = 16
                try {
                  size = font.sizes[0]
                } catch (err) {}

                obj.text = {
                  value,
                  transform,
                  size
                }
                obj.type = FONT_LAYER + ''
              }
              if (item.type !== 'group') {
                console.log(`time_${fIndex}_${index}`)
                console.time(`time_${fIndex}_${index}`)
                // const imgBase64 = item.layer.image.toBase64()
                const imgBase64 = item.layer.image.pixelData
                console.timeEnd(`time_${fIndex}_${index}`)
                obj.imgBase64 = imgBase64
              }
              forms.push(obj)
            })
            node.forms = forms
          })
          //在knifePsds添加额外psd信息

          Object.assign(knifeItem, psdInfo)
          URL.revokeObjectURL(url)
          return true

          // files.map((file) => {
          //   p.push(
          //     new Promise((resolve) => {
          //       const url = URL.createObjectURL(file)
          //       //将内存的url缓存起来，等待实例销毁的时候，清空缓存
          //       this.cacheUrlData.push(url)
          //       this.PSD.fromURL(url)
          //         // this.PSD.fromDroppedFile(file)
          //         .then((psd) => {
          //           //获取全局缩略图信息
          //           const thumbnailKey = psd.resources.typeIndex.thumbnail
          //           const thumbnail = psd.resources.resources[thumbnailKey]

          //           const png = {
          //             src: thumbnail.url
          //           }
          //           console.log('png', png)
          //           const psdInfo = psd.tree().export()
          //           console.log(1)
          //           psdInfo.thumbnail = png.src
          //           psdInfo.thumbnalWidth = thumbnail.width
          //           psdInfo.thumbnalHeight = thumbnail.height
          //           console.log(1)
          //           this.thumbnails.push(png.src)
          //           psdInfo.file = file

          //           psdInfo.nodes = psd.tree().descendants()
          //           console.log('nodes', nodes)
          //           const nodes = psdInfo.nodes
          //           nodes.map((node, fIndex) => {
          //             if (node.type !== 'group' || node.depth() !== 1) return
          //             const children = node._children || []
          //             const forms = []
          //             console.log('fIndexfIndex', fIndex)
          //             children.map((item, index) => {
          //               const { name, top, left, width, height, text } = item.export() || {}
          //               const type = this.getImageType(name)
          //               const obj = {
          //                 name,
          //                 top,
          //                 left,
          //                 width,
          //                 height,
          //                 type: type + ''
          //               }
          //               if (text) {
          //                 const { value, transform, font } = text
          //                 let size = 16
          //                 try {
          //                   size = font.sizes[0]
          //                 } catch (err) {}

          //                 obj.text = {
          //                   value,
          //                   transform,
          //                   size
          //                 }
          //                 obj.type = FONT_LAYER + ''
          //               }
          //               if (item.type !== 'group') {
          //                 console.log(`time_${fIndex}_${index}`)
          //                 console.time(`time_${fIndex}_${index}`)
          //                 const imgBase64 = item.layer.image.toBase64()
          //                 console.timeEnd(`time_${fIndex}_${index}`)
          //                 obj.imgBase64 = imgBase64
          //               }
          //               forms.push(obj)
          //             })
          //             node.forms = forms
          //           })
          //           //在knifePsds添加额外psd信息

          //           Object.assign(knifeItem, psdInfo)
          //           resolve(true)
          //         })
          //         .catch((err) => {
          //           resolve(true)
          //         })
          //     })
          //   )
          // })
          Promise.all(p).then(() => {
            this.psdListData = psdListData
            this.curPsd = this.psdListData[0]
          })

          this.psdListData = psdListData
          this.curPsd = this.psdListData[0]
        })
      } catch (err) {
        this.loading = false
      }
    },
    toggleCurPsd(index) {
      this.curPsd = this.psdListData[index]
    },
    finish() {
      return this.psdListData.every(({ success }) => success)
    },
    existUndoTask() {
      // console.log('this.psdListData', this.psdListData)
      const doingArrr = this.psdListData
        .filter(({ success }) => {
          return !success
        })
        .map(({ sizeName }) => sizeName)
      if (!doingArrr.length) return null
      return doingArrr
    },
    //图片加载完成后，隐藏loading
    loadend() {
      this.loading = false
    },
    async createStandard(data, curPsdData) {
      const primId = this.$route.query.primId
      const { code, detail } = await createStardandSize({
        prim_prod: primId,
        sizeName: curPsdData.sizeName
      })

      if ($SUC({ code })) {
        const { id } = detail
        const formData = new FormData()
        for (let [key, val] of data.entries()) {
          if (key !== 'id') {
            if (key === 'params') {
              formData.append(key, val)
              // console.log('JSON.stringify(val)', JSON.stringify(val))
            } else {
              formData.append(key, val)
            }
          }
        }
        formData.append('id', id)
        const { code } = await stardandAdd(formData)
        if ($SUC({ code })) {
          this.$message.success('创建标准尺码成功')
        }
      }
      // const { code, msg } = await add(data);
    },
    async doSubmit({ data: curPsdData, index: curIndex }) {
      const {
        id,
        file,
        children = [],
        nodes = [],
        document: {
          width: imageWidth,
          height: imageHeight,
          resources: { resolutionInfo: { h_res, v_res } = {} } = {}
        } = {}
      } = curPsdData || {}
      this.$set(curPsdData, 'loading', true)
      const params = {
        sizePsdConfig: {},
        sizeLayerGroupList: []
      }

      params.sizePsdConfig = {
        imageWidth,
        imageHeight,
        hdpi: h_res,
        vdpi: v_res,
        sizeId: id,
        psdPath: null
        // kl_psd_path: file,
      }
      const groupNodes = nodes.filter((item) => item.type === 'group' && item.depth() == 1)
      const formsList = groupNodes.map(({ forms }) => forms)
      const childrenOfChildren = children.filter((item) => item.type === 'group')
      const tmpPromiseArr = childrenOfChildren.map(async (item, index) => {
        const forms = formsList[index]
        let sizeLayerGroupImageList = []
        let sizeLayerGroupTextList = []
        let obj = {
          name: item.name,
          order: index,
          sizeId: id
        }

        obj.sizeLayerGroupImageList = sizeLayerGroupImageList
        obj.sizeLayerGroupTextList = sizeLayerGroupTextList
        params.sizeLayerGroupList.push(obj)
        return forms.map(async (form, index) => {
          if (form.text) {
            const {
              type,
              imagePath,
              text: { value, transform = {}, size },
              ...rest
            } = form
            sizeLayerGroupTextList.push(Object.assign({}, rest, transform, { value, size }))
          } else {
            // sizeLayerGroupImageList.push(Object.assign({}, form, {imagePath: ''}));
            const { imgBase64, ...resetForm } = form
            const data = await base64ToFile(imgBase64, 'tucengpicture.png')
            const p = $uploadOSSPics([
              {
                files: [data],
                prop: 'imagePath',
                dirPrefix: $ossDirMapWithType['6'],
                uuidPrefix: this.psdListData[curIndex].id
              }
            ]).then((uploadedObj) => {
              resetForm.imagePath = uploadedObj.imagePath
              sizeLayerGroupImageList.push(
                Object.assign({}, resetForm, {
                  name: createRandomNum() + form.name
                })
              )
            })
            return p
          }
        })
      })
      const nextTmpPromiseArr = tmpPromiseArr.map(async (pArr) => {
        return Promise.all(await pArr)
      })
      await Promise.all(await nextTmpPromiseArr)
      const errTypeArr = []
      params.sizeLayerGroupList.map(({ sizeLayerGroupImageList }, index) => {
        if (!Array.isArray(sizeLayerGroupImageList) && !sizeLayerGroupImageList.length) {
          return errTypeArr.push(index + 1)
        }
        const haveMaker = sizeLayerGroupImageList.find((item) => item.type == MAKER)
        if (!haveMaker) {
          errTypeArr.push(index + 1)
        }
      })

      if (errTypeArr.length) {
        const { kenifeLayer } = this.$refs
        this.$message.error(`psd中第${errTypeArr.join('、')}组块中未查找到膜层`)
        kenifeLayer.groupClick(errTypeArr[0] - 1)
        this.$set(curPsdData, 'loading', false)
        return
      }

      const uploadFile = await $uploadOSSPics([
        {
          files: [file],
          prop: 'psdPath',
          dirPrefix: $ossDirMapWithType['1'],
          uuidPrefix: this.psdListData[curIndex].id
        }
      ])
      // console.log('uploadFile.psdPath', uploadFile.psdPath)
      params.sizePsdConfig.psdPath = uploadFile.psdPath

      try {
        // if (curIndex == 0 && !this.knifeStandardStatus) {
        //   this.createStandard(formData, curPsdData);
        // }
        const { code, msg } = await add(params)
        //当为第一个尺码的时候，判断是否需要生成标准码！

        if ($SUC({ code })) {
          this.$set(curPsdData, 'success', true)
          if (!this.existUndoTask()) {
            this.$message.success('添加刀版图成功')
            setTimeout(() => {
              this.$router.replace('/product/maintain/knifeMange')
            }, 200)
          } else {
            const successMsg = `当前尺码处理完成，尺码："${this.existUndoTask().join('、')}"还未处理, 请前往处理！`
            console.log('successMsg', successMsg)
            this.$message.success(successMsg)
          }
        }
        if (msg) {
          this.$message({
            showClose: true,
            type: 'error',
            message: msg,
            duration: 2500
          })
        }
      } catch (err) {
        console.log(err)
      }
      this.$set(curPsdData, 'loading', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.checkKenifePage {
  .row {
    display: flex;
    .l-col {
      flex: 0 0 250px;
    }
    .m-col {
      margin: 0 10px;
      flex: 1;
      overflow: auto;
    }
    .r-col {
      flex: 0 0 350px;
    }
  }
}
</style>
